@import 'libs/assets/theme/mixins.scss';

.snackbar {
  :global .MuiSnackbarContent-root {
    background-color: $darkGray;
    padding: 0.5rem 0.75rem;
  }
  :global .MuiSnackbarContent-action {
    padding-left: 0;
    margin-right: unset;
    margin-left: unset;
  }
  :global .MuiSnackbarContent-message {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    margin-right: 1rem;
  }

  :global .MuiStack-root > * {
    color: white;
    svg,
    svg * {
      color: $white;
    }
  }
}

.snackbarMobile {
  :global .MuiSnackbarContent-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 0;
    gap: 0.5rem;
  }
  :global .MuiSnackbarContent-message {
    margin-right: unset;
  }
}

.button {
  color: $white;
  display: flex;
  gap: 0.5rem;
  padding: 0;
  margin-left: 1rem;
}
