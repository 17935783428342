.flexRow {
  display: flex;
  flex-direction: row;
}
.flexColumn,
.days,
.times {
  display: flex;
  flex-direction: column;
}

.times {
  margin-left: 0.25rem;
}
