@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.categories {
  cursor: pointer;
  display: inline-flex;
  color: $white;
  align-items: center;

  @include lg {
    padding-bottom: 0;
  }
}
