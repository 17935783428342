@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

$height: 5.25rem;
$mobileHeight: 3.5rem;
$mobileFullHeight: 7rem;

.topHeaderSection {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: $white;
  @include topSectionPaddingDesktopFull;
}

.mobileLoggedOut {
  .logo {
    position: absolute;
    z-index: 1;
  }
  .loginButton {
    flex: 1;
  }
}

.topHeaderContent {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  @include below-lg {
    max-width: $shopMaxWidth;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  gap: 2rem;
  @include below-lg {
    gap: 1rem;
  }
}

.logo {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-right: 1rem;
}

.logoSearchbarWrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.searchbar {
  display: flex;
  flex-grow: 1;
  margin-right: 1rem;
  height: 2rem;
  @include lg {
    margin-right: 1.5rem;
  }
}

.addressInfo {
  margin: 0 1.5rem;
}

:export {
  mobileFullHeight: $mobileFullHeight;
}
