@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.editButtonText {
  margin-left: 0.5rem;
}

.myStoreWrapper {
  margin: 1rem;
  padding-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $lightGray;
}

.myStore {
  margin-bottom: 0.5rem;
}

.footerWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footerButton {
  padding-left: 0;
  width: 100%;
  justify-content: flex-start;
}
