@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.menuroot {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  background-color: $lightGray8K;
  color: $darkGray;
  overflow: auto;

  .header {
    .toolbar {
      display: flex;
      padding: 1.5rem;
      align-items: center;

      .titleWrapper {
        width: 100%;
        text-align: center;
      }

      .closeIcon {
        height: 2rem;
        width: auto;

        path {
          fill: $darkGray;
        }
      }
    }
  }
}

.cartselection {
  .headerTitle {
    padding-right: 0 !important;
  }

  .explanation {
    padding: 0 1rem !important;
    display: block;
    color: $darkGray55K;

    h1 {
      margin-top: 2rem;
      font-size: 1rem;
    }
  }

  .list {
    padding: 0;
    margin: 0 1rem 1rem 1rem;

    .listItem {
      margin-bottom: 0.5rem;
      background: $white;
      border-radius: 4px;

      .listItemButton {
        height: 3rem;
      }
    }
  }
}
