@import 'libs/assets/theme/coreVariablesV2.scss';

.textWrapper {
  margin-right: 21%;
  position: absolute;
  z-index: 1;
  font-family: 'Lato', sans-serif;
  color: $darkGray;
}

.maintenanceImage {
  position: relative;
  margin-left: 21%;
}

//The following styling is mostly copy/paste from https://maintenance.obeta.io/
.header1 {
  font-size: 74px;
  font-weight: 900;
  line-height: 89px;
}

.header2 {
  font-size: 36px;
  font-weight: 400;
  line-height: 43px;
  margin-bottom: 20px;
}

.spanElement {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: $darkGray55K;
  margin: 0;
}
