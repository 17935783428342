@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.headerContainer {
  position: sticky;
  top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0 0 5px $black10pct;
  z-index: 1000;
  box-sizing: border-box;
  background-color: $white;
}

.shopHeader {
  top: 0;
  position: fixed;
}

.appHeader {
  padding-top: var(--obeta-safe-area-top-root);

  @include below-sm {
    padding-top: calc(0.75rem + var(--obeta-safe-area-top-root));
  }
}
