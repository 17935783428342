@import 'libs/assets/theme/mixins.scss';

.marginIframe {
  max-width: $shopMaxWidth;
  box-sizing: border-box;
  @include lg {
    max-width: calc(#{$shopMaxWidth} + 4rem);
    padding: 0;
  }
}
