@import 'libs/assets/theme/coreVariablesV2.scss';

.switchTargetToast {
  background: #50c8ff;
  padding: 0;
  border-radius: 4px;

  .MuiPaper-root {
    background: #36abe0;
  }

  .MuiSnackbarContent-message {
    width: 100%;
  }
}

.messageWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;

  .text,
  .btnText {
    color: $white;
  }
}
