@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

* {
  margin: 0;
  padding: 0;
}

html,
body,
.app,
main {
  height: 100%;
}

// useBreakpoints hook not working in _app.tsx
.page {
  @include pageMargin;
  @include pageDesktopMargin;
  @include pageDesktopWidth;
}

body {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  padding-right: 0 !important;
  @include scrollbar;

  &.no-scroll {
    overflow: hidden !important;
  }
}

.app {
  display: flex;
  justify-content: center;

  flex-direction: column;
}

main {
  width: 100%;
  margin: 0 auto;
}

// hide oxomi datasheet deeplink button
.oxomi-datasheet-deeplink-button,
.oxomi-product-order-quantity-cust {
  display: none !important;
}
