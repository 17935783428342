@import 'libs/assets/theme/coreVariablesV2.scss';

.main-content-wrapper {
  padding: calc(2rem + var(--obeta-safe-area-top-root)) 3rem 2rem 2.75rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $white;
  box-sizing: border-box;
  overflow: auto;
}

.overlayWrapper {
  background-color: $white;
  overflow: auto;
  width: 100%;
  position: relative;
}
