@import '../../../../../libs/assets/theme/mixins.scss';

.root {
  display: grid;
  grid-gap: 1rem;
  max-width: 45.6rem;
  max-height: 46.56rem;
  background-color: $white;
  padding: 2.5rem;
  overflow: auto;
  grid-template-rows: auto auto auto 1fr auto auto;
  @include below-sm {
    padding: calc(1.5rem + var(--obeta-safe-area-top-root)) 1.5rem
      calc(2.25rem + var(--obeta-safe-area-bottom-root, 0rem)) 1.5rem;
  }
}

.rootMobileSpecific {
  width: 100%;
  max-width: unset;
  max-height: unset;
}

.rootTabletSpecific {
  width: max-content;
}

.header {
  display: flex;
  justify-content: space-between;
  color: $darkGray;
}

.storesList {
  overflow: auto;
  margin-right: -2.5rem;
  @include below-sm {
    margin-right: -1.5rem;
  }
}

.mobileFooter {
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  padding: 17px 24px 16px 24px;
  bottom: var(--obeta-safe-area-bottom-root, 0rem);
  gap: 16px;
  justify-content: flex-end;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}

.storesByRegion {
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.storesHeader {
  background-color: $lightGray5K;
  padding: 0.5rem;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  color: $darkGray;
}

.storeWrapper {
  display: flex;
  margin-bottom: 1.5rem;
  flex: 1;
  &:last-child {
    margin-bottom: 0;
  }
  @include below-sm {
    gap: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
  }
}
.storeWithRadio {
  width: 29.25rem;
  flex-direction: row;
  @include below-sm {
    width: 100%;
  }
}

.selectStore {
  height: fit-content;
  padding: 0;
  margin-right: 0.5rem;

  @include below-lg {
    padding: 0.25rem;
  }

  & > span svg {
    width: 20px;
    height: 20px;

    @include below-lg {
      width: 24px;
      height: 24px;
    }
  }

  & > span svg path {
    transform: scale(1.2);
    transform-origin: center;
  }
}

.actions {
  display: grid;
  grid-auto-flow: column;
  width: fit-content;
  margin-left: auto;
  grid-gap: 1.5rem;
}
.btn-close {
  width: fit-content;
  margin-left: auto;
}
