@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.outerGrid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  max-width: 45.75rem;
  max-height: 39.5rem;
  width: 100%;
  height: 100%;

  @include sm {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    box-shadow: 0 5px 5px $black15pct, 0 -5px 5px $black15pct;
    border: none;
  }
}

.outerGridMobileSpecific {
  $padding: 1rem;
  padding: $padding;
  padding-top: calc($padding + var(--obeta-safe-area-top-root));
  padding-bottom: calc($padding + var(--obeta-safe-area-bottom-root));
  height: 100%;
  max-height: 100vh;
  .storeCardGrid {
    grid-template-columns: 1fr;
  }
}

.outerGridTabletSpecific {
  $padding: 1.5rem;
  padding: $padding;
  padding-top: calc($padding + var(--obeta-safe-area-top-root));
  padding-bottom: calc($padding + var(--obeta-safe-area-bottom-root));
  height: 100%;
}

.outerGridDesktopSpecific {
  max-width: 41rem;
  padding: 2.5rem;

  &.wide {
    max-width: 50rem;
    padding: 1.5rem;
  }
}

.gridHoldingUserAddressGridAndDivider {
  width: 100%;
  flex: 1;
  overflow: hidden;
  margin-top: 1rem;
  @include below-sm {
    // 8rem resembles sum of header, footer, gaps and padding
    height: calc(100% - calc(8rem));
  }
}

.headerBox {
  width: 100%;
}

.heading {
  display: flex;
  justify-content: space-between;
}
.storeCardGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}

.searchBoxWrapper {
  margin-top: 0.5rem;
  @include sm {
    margin-top: 1rem;
  }
}

.footerGrid {
  display: flex;
  padding-top: 0.5rem;
  width: 100%;
  justify-content: right;
  @include sm {
    padding-top: 1rem;
  }
}

.radioGroup {
  :global .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
  }
  :global .MuiRadio-root {
    padding: 0 0.5rem 0 0;
  }
}

.closeButton {
  :global .MuiButton-startIcon {
    margin: 0;
  }
  padding-right: 0;
  min-width: 0;
  :global .MuiButton-startIcon > *:nth-of-type(1) {
    font-size: 1.5rem;
  }
}

.submitButton {
  float: right;
}

.dividerWrapper {
  width: 100%;
}

.dividerWrapperBottom {
  width: 100%;
  margin: 21px 0 0 0;
}

.overlaysScrollbar {
  @include below-sm {
    height: 100%;
  }
  @include sm {
    min-height: 15.5rem; // TODO Why was this initially set to 245px?!
    max-height: 26rem; // https://www.figma.com/file/ecHZSQzDC4bIep2dpB8atn/Obeta-mit-Styleguide?node-id=2169-141828&t=GJGjEOtIHK7KKkjq-4 minus other content
  }

  // TODO Consolidate w/ former code
  // height: filteredStores && filteredStores.length < 5 ? '245px' : '413px'

  $scrollBarWidth: 0.5rem;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: $scrollBarWidth;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 31px;
    background-color: $darkGray55K;
  }
}

.storeCard {
  display: flex;
}

.applyButton {
  padding-top: 1rem;
  padding-right: 1.5rem;
}

.formControl {
  padding-right: 0.25rem;
  width: 100%;
}
