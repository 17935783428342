@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.cartWrapper {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $darkGray;
  svg {
    width: 20px;
    height: 20px;
  }
  @include below-lg {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.cart {
  display: flex;
  align-items: center;
  cursor: pointer;

  @include md {
    margin-left: 0;
  }
  &:active {
    opacity: 1;
    * {
      color: $darkGray55K;
    }
  }
}

.articlesCount {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: $darkGray;
}

.cartsSelector {
  display: flex;
  color: $darkGray55K;
  border-left: 1px solid $darkGray55K;
  &--ordarable {
    border-left: 1px solid $green;
  }
}

.cartMenuWrapper {
  width: 100%;

  @include md {
    width: auto;
  }
}
