@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/elevations.scss';

$height: 3.25rem;
$tabletHeight: 3.25rem;
$mobileHeight: 3.5rem;
$headerFullHeight: 8.5rem;

.hiddenSection {
  width: 100%;
  height: 0.25rem;
  background-color: $red100pct;
}
.bottomHeaderSection {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1.25rem;
  background-color: $white;
  border-top: 1px solid $lightGray8K;
  border-bottom: 2px solid $red100pct;

  @include sm {
    height: $tabletHeight;
    align-items: center;
    background-color: $red100pct;
    padding: 0;
    border: 0;
  }

  @include md {
    height: $height;
    background-color: $red100pct;
    padding: 0;
    border: 0;
  }

  .rightSideBottomSection {
    align-items: center;
    display: flex;
    gap: 2rem;
    height: 100%;
  }
}
.bottomHeaderSectionMobile {
  padding: 0.5rem 1rem 0.5rem;
}
.searchbar {
  margin-left: 1rem;
  height: 2rem;
  width: 100%;
}
.moreIcon {
  margin-left: 1rem;
  & div {
    height: 2rem;
  }
}
.bottomHeaderContent {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0.75rem 1.5rem;
  width: 100%;
  @include sm {
    padding: 0rem 2rem;
    max-width: calc(#{$shopMaxWidth} + 4rem);
  }
}
