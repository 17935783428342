@import 'libs/assets/theme/coreVariablesV2.scss';

.propertyContainer {
  display: flex;
  align-items: center;
}

.property {
  margin-right: 0.25rem;
  color: $red100pct;
}
