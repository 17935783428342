@import 'coreVariables';
@import 'coreVariablesV2';

:root {
  --descriptive-text-color: #929292;
  --actions-border: #e8e8e8;
  --menu-background-color: #f0f0f0;
  --warning-color: #f07682;
  --success-color: #27ae60;
  --default-card-background: #fff;
  --ghost-background: rgba(0, 0, 0, 0.05);
  --obeta-safe-area-top-root: var(--obeta-safe-area-top, env(safe-area-inset-top, 0px));
  --obeta-safe-area-bottom-root: var(--obeta-safe-area-bottom, env(safe-area-inset-bottom, 0px));
  --obeta-safe-area-right-root: var(--obeta-safe-area-right, env(safe-area-inset-right, 0px));
  --obeta-safe-area-left-root: var(--obeta-safe-area-left, env(safe-area-inset-left, 0px));
  --background-color: #{$lightGray5K};

  --color-primary: #{$primaryColor};
  --color-primary-rgb: 226, 6, 27;
  --color-primary-contrast: #fafafa;
  --color-primary-contrast-rgb: 250, 250, 250;
  --color-primary-shade: #{$primaryShade};
  --color-primary-tint: #{$primaryTint};

  --color-secondary: #{$secondaryColor};
  --color-secondary-rgb: 63, 63, 62;
  --color-secondary-contrast: #fafafa;
  --color-secondary-contrast-rgb: 250, 250, 250;
  --color-secondary-shade: #{$secondaryShade};
  --color-secondary-tint: #{$secondaryTint};

  --color-tertiary: #{$tertiaryColor};
  --color-tertiary-rgb: 250, 250, 250;
  --color-tertiary-contrast: #bdbdbd;
  --color-tertiary-contrast-rgb: 189, 189, 189;
  --color-tertiary-shade: #{$tertiaryShade};
  --color-tertiary-tint: #{$tertiaryTint};

  --grid-padding: 0.5rem;
  --grid-column-padding: 0.5rem;

  --popover-min-margin-bottom: 3.5rem;
}

@media (prefers-color-scheme: dark) {
  :root {
    /** primary **/
    --color-primary: #e3051b;
    --color-primary-rgb: 226, 6, 27;
    --color-primary-contrast: #fafafa;
    --color-primary-contrast-rgb: 250, 250, 250;
    --color-primary-shade: #{$primaryShade};
    --color-primary-tint: #{$primaryTint};
  }
}
