@import 'libs/assets/theme/mixins.scss';

.footerContainer {
  background-color: $white;
  color: $darkGray;
  display: flex;
  flex-direction: column;

  @include lg {
    flex-direction: row;
    justify-content: space-between;
  }
}

.boxLogos {
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1.5rem; //24px

  @include lg {
    margin-bottom: 2.5rem; //40px
    padding-left: 0;
  }
}

.obetaLogoColor {
  color: $red100pct;
  width: 7.125rem; //114px
  height: 2.5rem; //40px;
}

.SmsIcon {
  width: 1.25rem;
  height: 1.25rem;
}

.boxIconFollowedByText {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
  margin-left: 1rem;

  @include md {
    margin-bottom: 0;
    margin-left: 2rem; //32px;
    padding-top: 0.5rem;
  }

  @include lg {
    margin-bottom: 1rem;
    margin-left: 0;
    padding-top: 0;
  }
}

.textAfterIcon {
  margin-left: 0.5rem;
}

.divLogoAndIcons {
  display: flex;
  flex-direction: column;

  @include lg {
    flex-direction: column;
  }
}

.marginForKontakt {
  margin-bottom: 1.5rem; //24px
}

.mainDivLinks {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  @include lg {
    padding-left: 6.875rem;
  }

  @include md {
    flex-direction: row;
  }
}

.divLinks {
  min-width: 100px;
}

.displayBlock {
  display: block;
}

.dividerGray {
  border-color: $lightGray;
}

.divider {
  @extend .dividerGray;
  margin-left: 1rem;
  margin-right: 1rem;
}

.marginBottomOneAndHalfRem {
  margin-bottom: 1.5rem;
}

.marginBottomHalfRem {
  margin-bottom: 0.5rem;
}

.marginLeftHalfRem {
  margin-left: 0.5rem;
}

.marginLeftOnefRem {
  margin-left: 1rem;
}

.metalQuotationInfo {
  border-radius: 3.75rem;
  background-color: #f0f0f0;
  padding: 0.25rem 0.875rem;
  display: inline;
}

.sectionMetalQuotationCopyrightNote {
  padding-left: 1rem;
  background-color: $white;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  color: $darkGray;

  @include md {
    flex-direction: row;
    justify-content: space-between;
    padding-left: 0;
    margin-top: 1rem;
  }
}

.metalQuotationTitle {
  margin-bottom: 1rem;
  @include sm {
    margin-bottom: 0;
    display: inline;
  }
}

.marginLeft14px {
  margin-left: 0.875rem;
}

.marginRight14px {
  margin-right: 0.875rem;
}

.copyRightText {
  display: inline;
  color: $darkGray55K;
}

.outerDiv {
  background-color: $white;
}

.wrapper {
  padding-top: 2.5rem; //40px;
  background-color: $white;
  padding-bottom: 1rem;
  box-sizing: border-box;

  @include sm {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  @include lg {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.dividerSectionMetalQuotation {
  @extend .dividerGray;
  margin-top: 2.5rem; //40px;
  margin-bottom: 0.75rem; //12px;
}

.textCentered {
  text-align: center;
}

.instagramAndYoutubeLogos {
  width: 2.5rem; //40px;
  height: 2.5rem; //40px;

  @include lg {
    width: 1.25rem; // 20px;
    height: 1.25rem; // 20px;
  }
}

.appStoreAndGooglePlayLogos {
  width: 3.438rem; //55px
  height: 1rem;
}

.sideMarginsAuto {
  margin-left: auto;
  margin-right: auto;
}

.linkListItem {
  &:hover {
    background-color: unset;
  }
}

//Will be merged into one class if remains identical
.contentLeft {
  display: flex;
}
.contentRight {
  display: flex;
}

.boxLogoAndIconsAndContactUsTabletLandscape {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.verticallyAligned {
  vertical-align: middle;
}

.socketErrorContainer {
  padding: 1rem;
  text-align: center;
}
