@import 'libs/assets/theme/coreVariablesV2.scss';

.container {
  margin: 0.75rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
}

.storeHeader {
  border-bottom: 1px solid $lightGray;
}

.storeHeader,
.emptyStoreHeader {
  padding-bottom: 0.25rem;
  display: flex;
  align-items: center;
}

.address {
  margin-top: 0.25rem;
}

.contacts {
  margin: 1.5rem 0;
}
