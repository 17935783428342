@import 'coreVariables';

$modalMarginThreshold: 2.5rem;

$red100pct: #e2061b;
$red89pct: #e7362b;
$red55pct: #f28e73;
$red34pct: #f8bca8;
$primaryRed: #e3051b;

$orange: #f18805;
$orange89pct: #f39520;
$orange55pct: #f7be76;
$orange34pct: #fad6aa;

$gray1: #333333;
$gray3: #828282;
$darkGray: #3f3f3e;
$darkGray55K: #929292;
$darkGray34K: #bebebe;
$darkGray21K: #d8d8d8;

$lightGray: #e7e7e7;
$lightGray8K: #f0f0f0;
$lightGray5K: #f6f6f6;
$lightGray3K: #fafafa;

$green: #27ae60;
$greenLight: #87d1a6;

$black: #000000;
$black10pct: #0000001a;
$black15pct: #00000026;
$white: #ffffff;
$white50pct: #ffffff80;

// see breakpoint definition https://www.figma.com/file/ecHZSQzDC4bIep2dpB8atn/Obeta-mit-Styleguide?node-id=13240%3A466601
$screenXsMin: 0;
// smartphone
$screenSmMin: 768px;
// tablet portrait
$screenMdMin: 1024px;
// tablet landscape
$screenLgMin: 1174px;
// desktop

$shopXsThreshold: 1.5rem;
$shopMdThreshold: 2.25rem;
$marginSm: 1rem;
$marginLg: 1.5rem;
$slideTransitionTimeout: 300;
$shopMaxWidth: 78.125rem;
$shopMaxWidthExtended: calc(78.125rem + 4rem);

$mobileHeaderHeight: 7.25rem;

$shopLastElementPadding: 2.5rem;
$productStickyBoxHeight: 4.5rem;
$productStickyBoxHeightMobile: 4.25rem;

$desktopEtimItemHeight: 2.75rem;

$listsMaxSize: 5;
$listsGap: 0.5rem;

:export {
  red100pct: $red100pct;
  red89pct: $red89pct;
  red55pct: $red55pct;
  red34pct: $red34pct;

  orange: $orange;
  orange89pct: $orange89pct;
  orange55pct: $orange55pct;
  orange34pct: $orange34pct;

  darkGray: $darkGray;
  darkGray55K: $darkGray55K;
  darkGray34K: $darkGray34K;
  darkGray21K: $darkGray21K;

  lightGray: $lightGray;
  lightGray8K: $lightGray8K;
  lightGray5K: $lightGray5K;
  lightGray3K: $lightGray3K;

  green: $green;

  black: $black;
  black10pct: $black10pct;
  black15pct: $black15pct;
  white: $white;
  white50pct: $white50pct;

  // imported from legacy coreVariables.scss
  primaryColor: $primaryColor;
  primaryShade: $primaryShade;
  secondaryColor: $secondaryColor;
  secondaryShade: $secondaryShade;
  secondaryTint: $secondaryTint;
  tertiaryColor: $tertiaryColor;
  tertiaryShade: $tertiaryShade;
  tertiaryTint: $tertiaryTint;
  backdropHeightRem: $backdropHeightRem;
  backdropArticlesHeightRem: $backdropArticlesHeightRem;
  modalMarginThreshold: $modalMarginThreshold;
  screenXsMin: $screenXsMin;
  screenSmMin: $screenSmMin;
  screenMdMin: $screenMdMin;
  screenLgMin: $screenLgMin;
  slideTransitionTimeout: $slideTransitionTimeout;
  mobileHeaderHeight: $mobileHeaderHeight;
  shopMaxWidth: $shopMaxWidth;
  productStickyBoxHeight: $productStickyBoxHeight;
  productStickyBoxHeightMobile: $productStickyBoxHeightMobile;
  desktopEtimItemHeight: $desktopEtimItemHeight;
  listsMaxSize: $listsMaxSize;
  listsGap: $listsGap;
}
