@use 'sass:color';

//obeta colors:
$primaryColor: #e2061b;
$primaryShade: color.scale($primaryColor, $lightness: -10%);
$primaryTint: color.scale($primaryColor, $lightness: 10%);

$secondaryColor: #3f3f3e;
$secondaryShade: color.scale($secondaryColor, $lightness: -10%);
$secondaryTint: color.scale($secondaryColor, $lightness: 10%);

$tertiaryColor: #fafafa;
$tertiaryShade: color.scale($tertiaryColor, $lightness: -10%);
$tertiaryTint: color.scale($tertiaryColor, $lightness: 10%);

$backdropHeightRem: 4.7rem;
$backdropArticlesHeightRem: 6.5rem;

:export {
  primaryColor: $primaryColor;
  primaryShade: $primaryShade;
  secondaryColor: $secondaryColor;
  secondaryShade: $secondaryShade;
  secondaryTint: $secondaryTint;
  tertiaryColor: $tertiaryColor;
  tertiaryShade: $tertiaryShade;
  tertiaryTint: $tertiaryTint;
  backdropHeightRem: $backdropHeightRem;
  backdropArticlesHeightRem: $backdropArticlesHeightRem;
}
