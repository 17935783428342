@import 'libs/assets/theme/coreVariablesV2.scss';

.spin {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.circleLoader {
  border: 0.125rem solid rgba(0, 0, 0, 0.2);
  border-left-color: $green;
}

.circleLoader,
.circleLoader:after {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}
