@import '../../../../assets/theme/coreVariablesV2';

.carts-select {
  height: 100%;
  padding-left: 0.5rem;
  &--with-indicator {
    color: $green;
  }
}
.carts-color {
  color: $darkGray;
}
