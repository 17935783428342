@import 'libs/assets/theme/coreVariablesV2.scss';

.menuButton {
  align-items: center;
  display: flex;
  height: 100%;
  &Active {
    box-shadow: inset 0px -0.125rem $white;
  }
  &Enabled {
    &:hover {
      box-shadow: inset 0px -0.125rem $white;
    }
  }
  &Title {
    align-items: center;
    display: flex;
    &Disabled {
      cursor: default;
      color: $white50pct;
    }
    &Enabled {
      cursor: pointer;
      color: $white;
    }
  }
}
