@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.search-suggestion {
  &-wrapper {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $lightGray;

    &:last-child {
      margin: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
  &-items {
    margin-top: 1rem;
  }
  &-item {
    padding: 0.625rem 1.25rem;
    cursor: pointer;

    &-selected,
    &:hover {
      background-color: $lightGray5K;
    }
  }
  &-result {
    display: flex;
  }
  &-icon {
    margin-right: 0.75rem;
    display: flex;
    align-items: center;
  }
}
