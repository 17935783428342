.footerButton {
  width: 100%;
  justify-content: start;
  margin-right: 0.5rem;
  padding: 0.5rem 0 0 0;

  > svg {
    margin-right: 0.6rem;
  }
}
