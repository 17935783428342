.title {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.close {
  justify-content: end;
  padding-right: 0;

  span {
    margin-right: 0;
  }
}

.content {
  padding: 0 1rem 0 1rem;
}
