/*
 For some reason sass loader doesn't support nested imports
 ("hey/ho.scss" is supported, but "hey/hi/ho.scss" is not)
 This imports doesn't work only when used in ts(x)/js(x) files. Only storybook
*/

@import 'swiper/swiper-bundle.css';

.swiper-container-horizontal > .swiper-scrollbar {
  bottom: 0;
}

.swiper-scrollbar-drag {
  opacity: 0.5;
}
