@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.sign-up-footer-wrapper {
  width: 100%;
  background-color: white;
  padding: 0;
}

.footer-content {
  display: flex;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
}
.strength-card {
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.25rem;
  min-width: 15.625rem;
  @include above-sm {
    padding: 1rem 1.5rem;
  }
  @include lg {
    padding: 2rem 0;
    max-width: $shopMaxWidth;
    margin-left: auto;
    margin-right: auto;
  }
}
