@import 'libs/assets/theme/mixins.scss';

.layout {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;

  @include lg {
    gap: 0.5rem;
  }

  &.interactive {
    cursor: pointer;
  }
}

.addressLine {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
