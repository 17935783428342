@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/elevations.scss';
@import 'libs/assets/theme/mixins.scss';

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.strength {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: $white;
  border-radius: 0.25rem;
  text-align: center;
  box-sizing: border-box;
  @include dropShadow1;

  &:hover {
    cursor: pointer;
    @include dropShadow2;
  }

  @include below-sm {
    width: 10rem;
    flex-shrink: 0;
    flex-grow: 0;
  }
}

.strength-icon {
  height: 2.5rem;
  width: 2.5rem;
  margin-bottom: 0.5rem;
}

.name-wrapper {
  margin-bottom: 0.25rem;
}
