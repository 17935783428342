@import 'libs/assets/theme/coreVariablesV2.scss';

@mixin below-sm {
  @media (max-width: ($screenSmMin - 0.02px)) {
    @content;
  }
}

@mixin above-sm {
  @media (min-width: $screenSmMin) {
    @content;
  }
}

@mixin below-md {
  @media (max-width: ($screenMdMin - 0.02px)) {
    @content;
  }
}

@mixin below-lg {
  @media (max-width: ($screenLgMin - 0.02px)) {
    @content;
  }
}

@mixin above-md {
  @media (min-width: $screenMdMin) {
    @content;
  }
}

@mixin above-lg {
  @media (min-width: $screenLgMin) {
    @content;
  }
}

@mixin landscape-above-md {
  @media (min-width: $screenMdMin) and (orientation: landscape) {
    @content;
  }
}

@mixin landscape-below-md {
  @media (max-width: ($screenMdMin - 0.02px)) and (orientation: landscape) {
    @content;
  }
}

@mixin landscape-below-sm {
  @media (max-width: ($screenMdMin - 0.02px)) and (max-height: 500px) and (orientation: landscape) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (min-width: #{$screenSmMin}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screenMdMin}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screenLgMin}) {
    @content;
  }
}

// Hover devices (desktop)
@mixin hover {
  @media (hover: hover) {
    @content;
  }
}

// Container queries
@mixin container {
  container-type: inline-size;
}

@mixin cxs {
  @container (min-width: 20rem /* 320px */) {
    @content;
  }
}

@mixin csm {
  @container (min-width: 24rem /* 384px */) {
    @content;
  }
}

@mixin cmd {
  @container (min-width: 28rem /* 448px */) {
    @content;
  }
}

@mixin clg {
  @container (min-width: 32rem /* 512px */) {
    @content;
  }
}

@mixin cxl {
  @container (min-width: 36rem /* 576px */) {
    @content;
  }
}

@mixin cxl2 {
  @container (min-width: 42rem /* 672px */) {
    @content;
  }
}

@mixin cxl3 {
  @container (min-width: 48rem /* 768px */) {
    @content;
  }
}

@mixin cxl4 {
  @container (min-width: 56rem /* 896px */) {
    @content;
  }
}

@mixin cxl5 {
  @container (min-width: 64rem /* 1024px */) {
    @content;
  }
}

@mixin cxl6 {
  @container (min-width: 72rem /* 1152px */) {
    @content;
  }
}

@mixin cxl7 {
  @container (min-width: 80rem /* 1280px */) {
    @content;
  }
}

@mixin widthWithShopThreshold($property: 'width') {
  #{$property}: calc(100% + $shopXsThreshold);
  @include md {
    #{$property}: calc(100% + $shopMdThreshold);
  }
  @include lg {
    #{$property}: 100%;
  }
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
// in your styles file after applying of this mixin
// should be added `-webkit-line-clamp: NUMBER_OF_LINES;`
@mixin multilineEllipsis {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

@mixin underline {
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  text-underline-position: under;
}

@mixin modalHeaderStyles {
  padding: calc(0.5rem + var(--obeta-safe-area-top-root)) 0.5rem 0 0.5rem;
}

@mixin indicatorKeyframes(
  $name,
  $scaleStart: 0,
  $scaleEnd: 1,
  $duration: 1,
  $direction: alternate,
  $timingFunction: 'ease-in-out'
) {
  animation-duration: #{$duration}s;
  animation-timing-function: #{$timingFunction};
  animation-iteration-count: infinite;
  animation-direction: #{$direction};
  animation-name: #{$name};

  @keyframes #{$name} {
    0% {
      transform: scale($scaleStart);
    }
    @content;
    100% {
      transform: scale($scaleEnd);
    }
  }
}

@mixin pageMargin {
  position: relative;
  margin: 1.5rem 1rem;
  @include sm {
    margin: 1.5rem;
  }
}

@mixin pageDesktopMargin {
  @include lg {
    margin: 2rem 2rem 2.5rem 2rem;
  }
}

@mixin pageDesktopWidth {
  @include lg {
    max-width: 78.125rem;
  }
}

@mixin pageStartDesktopMargin {
  @include lg {
    margin: 0 2rem 0.5rem 2rem;
  }
}

@mixin pageStartDesktopMarginExtended {
  @include lg {
    margin: 4rem 2rem 4rem 2rem;
  }
}

@mixin pageStartDesktopWidth {
  @include lg {
    max-width: 78.125rem;
  }
}

@mixin pageWithFullWidth {
  position: relative;
  margin: 1.5rem auto;
}

@mixin columnGap($value: 0) {
  & > * {
    margin-right: $value;
    &:last-child {
      margin-right: 0;
    }
  }
}

@mixin rowGap($value: 0) {
  & > * {
    margin-bottom: $value;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0.5rem;
    height: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $darkGray55K;
    border-radius: 1.875rem;
  }

  scrollbar-color: $darkGray55K;
}

@mixin actionLightGrayTheme {
  border: 0.0625rem solid $lightGray;
  background-color: $white;
}

@mixin listMaxHeight($itemHeight: 0, $gap: 0) {
  max-height: $listsMaxSize * $itemHeight + $gap * $listsMaxSize - $gap;
  overflow: auto;
}

@mixin grayFullWidthBg {
  // Neighbouring page content must have a higher z-index and be positioned relatively
  position: absolute;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  background-color: $lightGray5K;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

@mixin shoppingCartPadding {
  @include below-sm {
    padding: 1rem;
  }
  @include above-sm {
    padding: 2rem;
  }
  @include lg {
    padding: 1.5rem 2rem;
  }
}

@mixin articleDetailLeftRightPadding {
  @include below-sm {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @include above-sm {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@mixin topSectionPadding {
  @include below-sm {
    padding: 0.625rem 1.5rem;
  }
  @include above-sm {
    padding: 1rem 2rem;
  }
  @include lg {
    padding: 1.375rem 2rem;
    max-width: calc(#{$shopMaxWidth} + 4rem);
  }
}

@mixin topSectionPaddingDesktopFull {
  @include below-sm {
    padding: 0.625rem 1.5rem;
  }
  @include above-sm {
    padding: 1rem 2rem;
  }
  @include lg {
    padding: 1.375rem 2rem;
  }
}

@mixin signUpSectionPadding {
  @include below-sm {
    padding: 1.25rem 1.5rem 1.375rem;
  }
  @include above-sm {
    padding: 1.25rem 1.5rem 1.375rem;
  }
  @include lg {
    padding: 1.125rem 1.5rem 1.375rem;
  }
}

@mixin userSelectNone {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin bodyBoldFont {
  font-size: 0.875rem;
  line-height: 1.25rem;
  opacity: 1;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  letter-spacing: normal;
}
