@import 'libs/assets/theme/mixins.scss';

.page {
  @include pageMargin;
  @include pageDesktopMargin;
  @include pageDesktopWidth;
}

.pageStart {
  @include pageMargin;
  @include pageStartDesktopMargin;
  @include pageStartDesktopWidth;
}

.pageUserRegistration {
  @include pageMargin;
  @include pageStartDesktopMarginExtended;
  @include pageStartDesktopWidth;
}

.pageWithFullWidth {
  @include pageDesktopMargin;
  @include pageDesktopWidth;
  @include pageWithFullWidth;
}
