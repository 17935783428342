@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';
.toolsListOverlay {
  width: 66%;
}
.carts-menu-wrapper {
  display: flex;
  flex-direction: column;
  width: 36rem;
  height: 30.1875rem;
  padding: 1rem;
  background-color: $white;
  color: $black;

  &-mobile {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-top: calc(1rem + var(--obeta-safe-area-top-root));
    padding-bottom: calc(1rem + var(--obeta-safe-area-bottom-root));
  }
}

.carts-menu-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid $lightGray;

  &-mobile {
    flex-direction: column;
  }
}

.carts-menu-open-carts-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  &-mobile {
    margin-top: 1rem;
  }
}

.carts-menu-active-block-header {
  margin: 1rem 0 0;
}

.carts-menu-carts-block {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  height: 100%;
  overflow: auto;
}

.carts-menu-carts-items {
  width: 50%;
  padding-right: 1rem;
  border-right: 1px solid $lightGray;
  &-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 0;
  }
}

.carts-menu-cart-content {
  width: 15.938rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;
}

.carts-menu-open-active-cart-btn {
  margin-top: 1.5rem;
}

.button-full-width {
  width: 100%;
}

.modal-position-wrapper {
  position: absolute;
}

.carts-menu-scrollbar {
  padding-right: 0.5rem;
}

.text-color-dark-gray {
  color: $darkGray;
}

.chevron-icon {
  color: $darkGray;
}
